var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_detail_pack"),
        visible: _vm.value,
        width: "820px",
        closable: false
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          !_vm.disallowEdit
            ? _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-3",
                      attrs: { type: "danger" },
                      on: { click: _vm.deleteRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", icon: "plus" },
                      on: { click: _vm.addRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.form.lines,
                    "row-key": function(r, i) {
                      return i
                    },
                    pagination: {
                      showTotal: function(total) {
                        return _vm.$t("lbl_total_items", { total: total })
                      }
                    },
                    scroll: { y: 320 },
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onChangeRow,
                      getCheckboxProps: function(r) {
                        return {
                          props: {
                            disabled: _vm.disallowEdit
                          }
                        }
                      }
                    }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    { key: "no", attrs: { width: "90px", "data-index": "no" } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qtyPack",
                      attrs: { "data-index": "qtyPack" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return _c("a-input-number", {
                              staticStyle: { width: "50%" },
                              attrs: {
                                formatter: _vm.formatterNumber,
                                parser: _vm.reverseFormatNumber,
                                precision: _vm.DECIMAL_PLACES_QTY,
                                readonly: _vm.disallowEdit,
                                min: 0
                              },
                              model: {
                                value: record.qtyPack,
                                callback: function($$v) {
                                  _vm.$set(record, "qtyPack", _vm._n($$v))
                                },
                                expression: "record.qtyPack"
                              }
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "locationId",
                      attrs: { "data-index": "locationId" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm.disallowEdit
                                ? _c("span", [
                                    _vm._v(_vm._s(record.location || "-"))
                                  ])
                                : _c("CSelectRack", {
                                    attrs: { "for-list": false },
                                    model: {
                                      value: record.locationId,
                                      callback: function($$v) {
                                        _vm.$set(record, "locationId", $$v)
                                      },
                                      expression: "record.locationId"
                                    }
                                  })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_location")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "newBatchNumber",
                      attrs: { width: "250px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              !record.newBatchNumber
                                ? _c(
                                    "span",
                                    { staticClass: "ant-form-explain" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lbl_batch_number_generate_after_submit"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(record.newBatchNumber || "-"))
                                  ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  ),
                  _c("template", { slot: "footer" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("lbl_qty_return")) +
                          ": " +
                          _vm._s(
                            _vm._f("toDecimalQty")(_vm.modalData.qtyReturn)
                          )
                      )
                    ]),
                    _c("p", { class: { "validate-error": _vm.isExceed } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lbl_total_qty")) +
                          ": " +
                          _vm._s(_vm._f("toDecimalQty")(_vm.calcTotalQty())) +
                          " "
                      )
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isExceed,
                            expression: "isExceed"
                          }
                        ],
                        staticClass: "validate-error"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_total_qty_is_exceed")) + " "
                        )
                      ]
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleClose } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
          ]),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                icon: "save",
                disabled: _vm.disabledSave
              },
              on: { click: _vm.handleSave }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }