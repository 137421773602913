



































































































































import { ResponseDetailSalesReturn, SalesReturnItemDetail } from "@/models/interface-v2/sales-return.interface";
import { Row, DISALLOW_EDIT } from "../CustomerReturnForm.vue";
import { Decimal } from "decimal.js-light";
import Vue from "vue";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY } from "@/models/constant/global.constant";

export default Vue.extend({
  name: "ModalPack",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object as () => ResponseDetailSalesReturn,
      default: undefined,
    },
    modalData: {
      type: Object as () => Row,
      default: undefined,
    },
    modalIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      DECIMAL_PLACES_QTY,
      form: {
        lines: [] as SalesReturnItemDetail[],
      },
      selectedRowKeys: [] as number[],
      deletedRow: [] as string[],
    };
  },
  computed: {
    disallowEdit(): boolean {
      return DISALLOW_EDIT.includes(this.detail.status);
    },
    isExceed(): boolean {
      return this.calcTotalQty() > this.modalData.qtyReturn;
    },
    isUnder(): boolean {
      return this.calcTotalQty() < this.modalData.qtyReturn;
    },
    disabledSave(): boolean {
      return this.isExceed || this.isUnder;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: "onView",
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    onChangeRow(keys: number[]): void {
      this.selectedRowKeys = keys;
    },
    addRow() {
      this.form.lines.unshift({
        no: this.form.lines.length,
        id: null,
        locationId: "",
        qtyPack: 0,

        location: "",
        newBatchId: "",
        newBatchNumber: "",
        newPackDate: "",
      });
      this.setColNumber();
    },
    deleteRow() {
      const { lines } = this.form;
      const newLines: SalesReturnItemDetail[] = [];

      lines.forEach((_x, i) => {
        if (this.selectedRowKeys.includes(i)) {
          this.deletedRow.push(_x.id ?? "");
        } else {
          newLines.push(_x);
        }
      });
      this.form.lines = [...newLines];
      this.selectedRowKeys = [];
      this.setColNumber();
    },
    setColNumber(): void {
      this.form.lines.forEach((x, i) => x.no = i + 1);
    },
    onSelectRack(e: string, idx: number): void {
      this.form.lines[idx].locationId = e;
    },
    calcTotalQty(): number {
      return this.form.lines.reduce((a, b) => new Decimal(b.qtyPack ?? 0).plus(a).toNumber(), 0);
    },
    // onChangeQty(e: number, index: number): void {
    //   if (isNaN(+e)) return;
    //   this.form.lines[index].qtyPack = e;
    // },
    onView(): void {
      if (!this.value) return;
      this.selectedRowKeys = [];
      this.deletedRow = [];
      this.form.lines = [...this.modalData.detailResponseDTOS ];
      this.setColNumber();
    },
    handleClose(): void {
      this.$emit("input", false);
    },
    handleSave(): void {
      this.$emit("on-save", {
        lines: this.form.lines,
        deletedItem: this.deletedRow,
      });
      this.handleClose();
    },
  }
});
